<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex">
        <nav id="breadcrumbs" class="mb-3">
          <ul>
            <li>
              <router-link :to="{ name: 'company.dashboard' }">
                <i class="uil-home-alt"></i>
              </router-link>
            </li>
            <li>{{ this.$t('menu.company_dashboard.manage_posts') }}</li>
          </ul>
        </nav>
      </div>

      <div id="shareSettings" class="uk-modal" uk-modal>
        <div class="uk-modal-dialog bggray">
          <div class="uk-padding-small" uk-grid>
            <h4>Paylaşım Ayarları</h4>
          </div>
          <div class="row p-4">
            <div class="col-lg-12">
              <div class="form-group row mb-2">
                <label class="col-md-6 col-form-label" for="website_keywords">
                  Onay Gerekli Değil
                  <i class="uil-info-circle" uk-tooltip="title: " title="" aria-expanded="false"></i></label>
                <div class="col-md-6">
                  <label class="uk-switch" for="default-1" v-if="experience_shareable != null">
                    <input type="checkbox" id="default-1" v-model="experience_shareable"
                      @change="handleExperienceSharing" />
                    <div class="uk-switch-slider"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="uk-modal-footer uk-text-right bggray">
            <button class="uk-button uk-button-default small uk-modal-close" type="button">
              <i class="uil-times"></i> Kapat
            </button>
          </div>
        </div>
      </div>

      <div class="section-header mb-lg-2 border-0 uk-flex-middle">
        <div class="section-header-left">
          <h2 class="uk-heading-line text-left"><span>{{ this.$t('general.sharings') }} </span></h2>
        </div>
        <div class="section-header-right">
          <a class="btn btn-default dropdown-arrow text-white" uk-toggle="target: #shareSettings"
            uk-tooltip="Paylaşım Ayarları">
            Paylaşım Ayarları
          </a>
        </div>
      </div>

      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>

      <div class="card">
        <!-- Card header -->
        <div class="card-header actions-toolbar border-0">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h4 class="d-inline-block mb-0"></h4> -->
            <div class="row" style="width:100%">
              <div class=" col-md-1  search-icon ">
                <i class="uil-search"></i>
              </div>
              <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
                <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                  :placeholder="$t('general.search')" autofocus="" />
                <!-- <a href="#" class="btn btn-icon btn-hover btn-circle" aria-expanded="false">
                <i class="uil-search"></i>
              </a>
              <div class="uk-drop" uk-drop="mode: hover; pos: right-center; offset: 0" style="left: 91px; top: 159.297px;">
                <form class="uk-search uk-search-navbar uk-width-1-1">
                  <input class="uk-search-input shadow-0 uk-form-small"
                  type="search"
                    v-model="filter.search"
                    :placeholder="$t('general.search')" autofocus="">
                </form>-->
              </div>
            </div>
            <div class="d-flex">
              <!-- Shorting -->
              <a href="#" class="btn btn-icon btn-hover btn-circle" :uk-tooltip="$t('filters.order.sort')" title=""
                aria-expanded="false">
                <i class="uil-list-ui-alt"></i>
              </a>
              <div uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up" class="uk-dropdown">
                <ul class="uk-nav uk-dropdown-nav">
                  <li @click="sortOrder('text', 'asc')">
                    <a href="javascript:void(0)">{{ $t("sorting.a_z") }}</a>
                  </li>
                  <li @click="sortOrder('text', 'desc')">
                    <a href="javascript:void(0)">{{ $t("sorting.z_a") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'asc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_asc") }}</a>
                  </li>
                  <li @click="sortOrder('created_at', 'desc')">
                    <a href="javascript:void(0)">
                      {{ $t("sorting.created_at_desc") }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Table -->
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">Kullanıcı</th>
                <th scope="col">{{ this.$t('general.type') }}</th>
                <th scope="col">{{ this.$t('general.sharing') }}</th>
                <th scope="col">{{ this.$t('general.added_at') }}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="list">
              <!--user item-->
              <tr v-for="item in formattedComments">
                <th scope="row">
                  <div class="media align-items-center">
                    <div>
                      <div class="avatar-parent-child" style="width: max-content">
                        <img alt="Image placeholder" :src="item.user_image" class="avatar  rounded-circle" />
                        <span :class="item.is_online ? 'bg-success' : 'bg-offline'"
                          class="avatar-child avatar-badge"></span>
                      </div>
                    </div>
                    <div class="media-body ml-4">
                      <a href="javascript:void(0)" class="name h6 mb-0 text-sm">{{ item.user_name }}</a>
                      <small class="d-block font-weight-bold">#{{ item.user_id }}</small>
                    </div>
                  </div>
                </th>
                <td>{{ $t(item.type) }}</td>
                <th scope="row">
                  <div class="media align-items-center">
                    <div>
                      <div class="avatar-parent-child">
                        {{ item.text }}
                      </div>
                    </div>
                    <div class="media-body ml-4">
                      <router-link :to="{ name: 'company.gift.add' }" class="name h6 mb-0 text-sm">
                        {{ item?.name }}
                      </router-link>
                    </div>
                  </div>
                </th>
                <td>{{ item.created_at }}</td>
                <td class="text-right">
                  <div class="actions ml-3" v-if="status_id === 1">
                    <a @click="setStatus(item.id, item.data_type, 2)" class="btn btn-icon btn-hover btn-sm btn-circle"
                      uk-tooltip="Onayla">
                      <i class="uil-check-circle text-success"></i>
                    </a>
                    <a href="javascript:void(0)" @click="removeItem(item.id, item.data_type)"
                      class="btn btn-icon btn-hover btn-sm btn-circle" uk-tooltip="Kapat" title="" aria-expanded="false">
                      <i class="uil-minus-circle text-danger"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "@/assets/components/dataTable/DataTable";
import store from "@/core/services";
import moment from "moment";
import comment_module, {
  MODULE_NAME,
  ITEMS,
  GET_ITEMS,
  UPDATE_ITEM,
  DELETE_ITEM,
  ERROR,
} from "@/core/services/store/comment.module";

import company_module, {
  ONLINE_USERS,
  MODULE_NAME as COMPANY_MODULE,
} from "@/core/services/store/company.module";

import {
  GET_ITEM_DETAIL_BY_ID,
  UPDATE_ITEM_BY_ID,
} from "@/core/services/store/REST.module";
import Pagination from "@/assets/components/Pagination";
const _COMPANY_MODULE = COMPANY_MODULE;

export default {
  name: "GiftList",
  components: {
    DataTable,
    Pagination
  },
  data() {
    return {
      companyUrl: "api/company/self",
      companySharingUrl: "api/company/experience-sharing/",
      sharesUrl: "api/shares-management",
      status_id: 1,
      pagination: null,
      activeItem: null,
      successMessage: null,
      errorMessages: [],
      page: 1,
      company: null,
      experience_shareable: null,
      per_page: 3,
      filter: {
        search: null,
        order: "desc",
        sort: "created_at",
      },
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, comment_module);
    registerStoreModule(_COMPANY_MODULE, company_module);
  },
  computed: {
    onlineUsers() {
      return store.getters[_COMPANY_MODULE + "/" + ONLINE_USERS];
    },
    comments: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    formattedComments() {
      let results = [];
      if (this.comments === null || !("data" in this.comments)) return [];

      if (typeof this.comments.data === "undefined") return [];

      this.comments.data.forEach((currentItem) => {
        if (currentItem?.user === undefined) return [];

        results.push({
          user_name: currentItem?.user?.name + " " + currentItem?.user?.surname,
          user_image: currentItem?.user?.photo_link,
          is_online: this.onlineUsers.find(
            (onlineUser) =>
              onlineUser.user == currentItem?.user?.id && onlineUser.status
          )
            ? true
            : false,
          user_id: currentItem?.user?.id,
          text: currentItem?.text,
          id: currentItem?.id,
          type: currentItem?.type,
          data_type: currentItem?.data_type,
          status_id: currentItem?.status_id,
          created_at: currentItem?.created_at
            ? moment(currentItem?.created_at).format("DD/MM/YYYY HH:mm:ss")
            : "-",
        });
      });

      return results;
    },
  },
  methods: {
    getCommentsResult() {
      let self = this;
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: self.sharesUrl,
        filters: {
          search: self.filter.search,
          page: self.page,
          per_page: self.per_page,
          sort: self.filter.sort,
          order: self.filter.order,
          status_id: self.status_id,
          with_user: true,
        },
      }).then((response) => {
        this.pagination = response;

      });
    },
    setStatus(id, type, status_id) {
      let self = this;
      this.$store
        .dispatch(MODULE_NAME + "/" + UPDATE_ITEM, {
          url: self.sharesUrl + "/" + id,
          contents: {
            status_id: status_id,
            type: type,
          },
        })
        .then(() => {
          if (this.error) {
            this.errorMessages.push(this.error);
          } else {
            this.successMessage = this.$t("general.successfully_updated");
            this.getCommentsResult();
          }
        });
    },
    removeItem(id, type) {
      let self = this;
      this.$store
        .dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
          url: self.sharesUrl + "/" + id + "/" + type,
          contents: {
            type: type,
          },
        })
        .then(() => {
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_updated");
            this.getCommentsResult();
          } else this.errorMessages.push(this.error);
        });
    },

    getCompany() {
      let self = this;
      this.$store
        .dispatch(GET_ITEM_DETAIL_BY_ID, {
          url: self.companyUrl,
          acceptPromise: true,
        })
        .then((result) => {
          if (result.status) {
            self.experience_shareable = result.data.experience_shareable
              ? true
              : false;
            self.company = result.data;
          }
        });
    },
    handleExperienceSharing() {
      let self = this;
      let formData = new FormData();
      formData.append(
        "experience_shareable",
        self.experience_shareable === true ? 1 : 0
      );
      this.$store.dispatch(UPDATE_ITEM_BY_ID, {
        url: self.companySharingUrl + self.company.id,
        contents: formData,
        acceptPromise: false,
      });
    },
    sortOrder(sort, order) {
      let self = this;
      self.page = 1;
      self.filter.order = order;
      self.filter.sort = sort;

      this.getCommentsResult();
    },
    setPage(page) {
      let self = this;
      self.page = page;
      this.getCommentsResult();
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getCompany();
    this.getCommentsResult();
  },
  beforeDestroy() {
    const modal = UIkit.modal("#shareSettings");
    if (modal) modal.$destroy(true);
  },
  watch: {
    "filter.search": function (val) {
      this.page = 1;
      this.debouncedFilterSearch();
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.getCommentsResult, 500);
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
